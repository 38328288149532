/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Global Styles */
body {
  margin: 0;
  padding: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: #f9fafb; /* Light neutral background */
  color: #fff; /* Darker text color for better readability */
  line-height: 1.6; /* Better line height for readability */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s, color 0.3s; /* Smooth transitions */
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
  body {
    background-color: #1f2937; /* Dark background for dark mode */
    color: #000; /* Light text color for better contrast */
  }

  a {
    color: #60a5fa; /* Light blue links for dark mode */
  }

  /* Example for buttons or other elements */
  button {
    background-color: #374151;
    color: #f9fafb;
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 600; /* Make headings bold */
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

a {
  color: #3b82f6; /* Modern blue for links */
  text-decoration: none;
  transition: color 0.2s;
}

a:hover {
  color: #2563eb; /* Darker blue on hover */
  text-decoration: underline;
}

/* Better code block styles */
code {
  font-family: 'Fira Code', 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: #f1f5f9; /* Light gray background */
  padding: 2px 4px;
  border-radius: 4px;
}

pre {
  font-family: 'Fira Code', 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
  background-color: #f3f4f6;
  padding: 1em;
  border-radius: 8px;
  overflow-x: auto;
}

/* Modern button styles */
button {
  background-color: #3b82f6;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0.75em 1.5em;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #2563eb;
}

/* Utility spacing (can adjust based on preferences) */
.container {
  padding: 0 1rem;
}

section {
  padding: 2rem 0;
}

/* Responsive design improvements */
@media (min-width: 768px) {
  .container {
    max-width: 768px;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

